import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b03fc96"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "costing-parameter-details",
  ref: "materialParametersWrapperRef"
}
const _hoisted_2 = {
  key: 5,
  class: "costing-parameter-details--page-counters",
  id: "costing-parameters-pagination"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Loader"], {
      loading: $setup.initLoading || $setup.loading
    }, null, 8, ["loading"]),
    ($setup.showCannotDeleteMaterialGroupPopup)
      ? (_openBlock(), _createBlock($setup["DefaultModal"], {
          key: 0,
          class: "info-modal cannot-delete-modal",
          show: $setup.showCannotDeleteMaterialGroupPopup,
          "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (($setup.showCannotDeleteMaterialGroupPopup) = $event)),
          title: "Cannot Delete",
          text: "You cannot delete material group that is connected to an existing raw material.",
          infoModal: true,
          onClose: _cache[1] || (_cache[1] = ($event: any) => ($setup.showCannotDeleteMaterialGroupPopup = false))
        }, null, 8, ["show"]))
      : _createCommentVNode("", true),
    ($setup.showCannotDeleteModal)
      ? (_openBlock(), _createBlock($setup["DefaultModal"], {
          key: 1,
          class: "info-modal cannot-delete-modal",
          show: $setup.showCannotDeleteModal,
          "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => (($setup.showCannotDeleteModal) = $event)),
          title: "Cannot Delete",
          text: "You cannot delete costing parameter that is connected to existing parts costing.",
          infoModal: true,
          onClose: _cache[3] || (_cache[3] = ($event: any) => ($setup.showCannotDeleteModal = false))
        }, null, 8, ["show"]))
      : _createCommentVNode("", true),
    ($setup.showConfirmDeletionModal)
      ? (_openBlock(), _createBlock($setup["DefaultModal"], {
          key: 2,
          class: "action-modal confirm-deletion-modal",
          show: $setup.showConfirmDeletionModal,
          "onUpdate:show": _cache[4] || (_cache[4] = ($event: any) => (($setup.showConfirmDeletionModal) = $event)),
          title: "Delete row",
          text: "Are you sure you want to delete this raw material?",
          actionText: "Yes, delete",
          withErrorTypeButton: false,
          onAction: $setup.onDeleteRow,
          onClose: _cache[5] || (_cache[5] = ($event: any) => ($setup.showConfirmDeletionModal = false))
        }, null, 8, ["show"]))
      : _createCommentVNode("", true),
    ($setup.showAddRawMaterialModal)
      ? (_openBlock(), _createBlock($setup["AddRawMaterialModal"], {
          key: 3,
          show: $setup.showAddRawMaterialModal,
          "onUpdate:show": _cache[6] || (_cache[6] = ($event: any) => (($setup.showAddRawMaterialModal) = $event)),
          materialGroupOptions: $setup.materialGroupOptions,
          shapesOptions: $setup.shapesOptions,
          mfrRegions: $setup.mfrRegions,
          availabilityOptions: $setup.availabilityOptions,
          materialAlternativesOptions: $setup.materialAlternativesOptions,
          onUpdate: $setup.fetchData,
          onClose: _cache[7] || (_cache[7] = ($event: any) => ($setup.showAddRawMaterialModal = false)),
          onAddOption: $setup.onAddOption,
          onDeleteOption: $setup.onDeleteOption,
          onChangeOption: $setup.onChangeOption
        }, null, 8, ["show", "materialGroupOptions", "shapesOptions", "mfrRegions", "availabilityOptions", "materialAlternativesOptions"]))
      : _createCommentVNode("", true),
    ($setup.showAddEquivalentSpecsModal)
      ? (_openBlock(), _createBlock($setup["AddEquivalentSpecsModal"], {
          key: 4,
          show: $setup.showAddEquivalentSpecsModal,
          "onUpdate:show": _cache[8] || (_cache[8] = ($event: any) => (($setup.showAddEquivalentSpecsModal) = $event)),
          materialId: $setup.equivalentSpecsMaterialId,
          materialName: $setup.equivalentSpecsMaterialName,
          materialGroup: $setup.equivalentSpecsMaterialGroup,
          materialGroupOptions: $setup.materialGroupOptions,
          equivalentSpecsValue: $setup.equivalentSpecsValue,
          onClose: _cache[9] || (_cache[9] = ($event: any) => ($setup.showAddEquivalentSpecsModal = false)),
          onUpdate: $setup.onUpdateEquivalentSpec
        }, null, 8, ["show", "materialId", "materialName", "materialGroup", "materialGroupOptions", "equivalentSpecsValue"]))
      : _createCommentVNode("", true),
    _createVNode($setup["CostingParametersOptions"], {
      showAddLine: !$setup.isCostingParametersViewerRole,
      onSelectPlusOption: $setup.onSelectPlusOption
    }, null, 8, ["showAddLine"]),
    _createElementVNode("div", {
      class: "costing-parameters-table-wrapper",
      ref: "tableRef",
      style: _normalizeStyle({minHeight: `${$setup.maxHeight}px`})
    }, [
      (!$setup.initLoading)
        ? (_openBlock(), _createBlock($setup["NDataTable"], {
            key: 0,
            class: _normalizeClass(["material costing-parameters-table", {'disable-scroll': $setup.validationError || $setup.isEditModeCell}]),
            columns: $setup.columns,
            data: $setup.data,
            "single-line": false,
            "max-height": $setup.maxHeight - $setup.tableHeaderHeight,
            style: _normalizeStyle({height: `${$setup.maxHeight}px`}),
            "onUpdate:sorter": $setup.handleSorterChange
          }, null, 8, ["columns", "data", "max-height", "style", "class", "onUpdate:sorter"]))
        : _createCommentVNode("", true)
    ], 4),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _withDirectives(_createElementVNode("div", { class: "costing-parameter-details--counter" }, _toDisplayString($setup.paginationCurrentItemsMessage), 513), [
            [_vShow, !$setup.initLoading]
          ]),
          _withDirectives(_createVNode($setup["NPagination"], {
            class: "costing-parameter-details--pagination",
            page: $setup.currentPage,
            "onUpdate:page": [
              _cache[10] || (_cache[10] = ($event: any) => (($setup.currentPage) = $event)),
              _cache[11] || (_cache[11] = ($event: any) => ($setup.onPaginationUpdated()))
            ],
            "page-count": $setup.totalPages,
            "page-slot": 6
          }, null, 8, ["page", "page-count"]), [
            [_vShow, $setup.totalPages > 1]
          ])
        ]))
      : _createCommentVNode("", true)
  ], 512))
}