import { createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e1c1062"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-form" }
const _hoisted_2 = { class: "add-equivalent-specs-modal-actions costing-parameters-action-modal-actions main-modal-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: "add-equivalent-specs-modal costing-parameters-action-modal",
    preset: "dialog",
    "show-icon": false,
    title: "Equivalent specs"
  }, {
    default: _withCtx(() => [
      _createVNode($setup["Loader"], { loading: $setup.loading }, null, 8, ["loading"]),
      _createVNode($setup["NForm"], {
        class: "costing-parameters-action-form",
        ref: "formRef",
        model: $setup.form
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode($setup["NFormItem"], {
              required: "",
              path: "name",
              label: "Material name (grade)"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["NInput"], {
                  value: $props.materialName,
                  placeholder: "Enter",
                  disabled: ""
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode($setup["NFormItem"], {
              required: "",
              path: "materialGroupId",
              label: "Material group"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["SelectableTableCell"], {
                  value: $props.materialGroup,
                  options: $props.materialGroupOptions,
                  filterable: "",
                  addable: "",
                  selectInForm: "",
                  disabled: ""
                }, null, 8, ["value", "options"])
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.EquivalentSpecsNameEnum, (value, key) => {
              return (_openBlock(), _createBlock($setup["NFormItem"], {
                key: key,
                path: `${key}`,
                label: value
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["NInput"], {
                    value: $setup.form[key],
                    "onUpdate:value": ($event: any) => (($setup.form[key]) = $event),
                    placeholder: "Enter",
                    onBlur: ($event: any) => ($setup.form[key] = $setup.onCleanExtraSpaces($setup.form[key]))
                  }, null, 8, ["value", "onUpdate:value", "onBlur"])
                ]),
                _: 2
              }, 1032, ["path", "label"]))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["model"]),
      _createElementVNode("div", _hoisted_2, [
        ($props.equivalentSpecsValue?.length)
          ? (_openBlock(), _createBlock($setup["NButton"], {
              key: 0,
              class: "update-button n-button-save medium-button",
              type: "info",
              onClick: $setup.onUpdateEquivalentSpecs
            }, {
              default: _withCtx(() => [
                _createTextVNode("Update")
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock($setup["NButton"], {
              key: 1,
              class: "update-button n-button-save medium-button",
              type: "info",
              onClick: $setup.onAddEquivalentSpecs
            }, {
              default: _withCtx(() => [
                _createTextVNode("Add")
              ]),
              _: 1
            })),
        _createVNode($setup["NButton"], {
          class: "medium-button n-button-cancel",
          type: "tertiary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Cancel")
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}